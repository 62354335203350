import React from 'react'
import { Link } from 'react-router-dom'
import NewsletterComponent from '../newsletter'
import { FaFacebook, FaLinkedin, FaYoutube, FaXTwitter } from 'react-icons/fa6';
import LogoSliderBottomComponent from '../logoSlider';
import { DEVELOPER_MIMIK_URL } from '../../constant/path'
import packagejson from '../../../package.json'

const FooterComponent = ({
    showFooter
}) => {
    return (
        <>
            {showFooter ? <LogoSliderBottomComponent /> : null}
            <div className='container_fluid footer_bg'>
                <div className='container footer_section'>
                    <div className='bottom_links bottom_links_mobile show_on_mobile'>
                        <div className='bottom_link_two'>
                            <h6><a target='_blank' rel='noopener noreferrer' href={DEVELOPER_MIMIK_URL + 'login'}>developer console</a></h6>
                            <h6><Link to={'/developer-agreement'}>developer agreement</Link></h6>
                        </div>
                        <div className='bottom_link_two'>
                            {/* <h6 className='top_btmgap'><a target='_blank' rel='noopener noreferrer' href='https://mimik.com/'>mimik website</a></h6>
                            <h6><a target='_blank' rel='noopener noreferrer' href='https://mimik.com/lets-edgifi-blog/'>let’s edgifi blog</a></h6>
                            <h6><a target='_blank' rel='noopener noreferrer' href='https://mimik.com/technical-post/'>technical posts</a></h6>
                            <h6><a target='_blank' rel='noopener noreferrer' href='https://mimik.com/event/'>events</a></h6> 
                            <h6><a target='_blank' rel='noopener noreferrer' href='https://mimik.com/about-us/'>about us</a></h6> */}
                        </div>
                    </div>
                    <div className='footer_content'>
                        <div className='bottom_links'>
                            <h6>home</h6>
                            <ul>
                                <li><Link to={'/'}>developer portal v{packagejson.version}</Link></li>

                            </ul>
                        </div>
                        <div className='bottom_links hide_on_mobile'>
                            <h6>downloads</h6>
                            <ul>
                                <li><Link to={'/downloads/'}>edgeEngine</Link></li>
                            </ul>
                        </div>
                        <div className='bottom_links'>
                            <h6>training</h6>
                            <ul>
                                <li><Link to={'/edgeengine-and-microservices/overview'}>get started with mimik</Link></li>
                                <li><Link to={'/complementary-courses/'}>complementary courses</Link></li>

                            </ul>
                        </div>
                        <div className='bottom_links show_on_mobile'>
                            <h6>downloads</h6>
                            <ul>
                                <li><Link to={'/downloads/'}>edgeEngine</Link></li>
                            </ul>
                        </div>
                        <div className='bottom_links'>
                            <h6>community</h6>
                            <ul>
                                <li><Link to={'/hec-community/'}>HEC community</Link></li>
                                <li><Link to={'/community/'}>discussion groups</Link></li>
                                <li><Link to={'/support/'}>support</Link></li>
                            </ul>
                        </div>
                        <div className='bottom_links show_on_mobile'>
                            <h6>docs</h6>
                            <ul>
                                <li><a target='_blank' rel='noopener noreferrer' href={'https://devdocs.mimik.com/'}>developer documentation</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='bottom_links hide_on_mobile'>
                        <h6>docs</h6>
                        <ul>
                            <li><a target='_blank' rel='noopener noreferrer' href='https://devdocs.mimik.com/'>developer documentation</a></li>
                            <li><a target='_blank' rel='noopener noreferrer' href='https://devdocs.mimik.com/tutorials'>tutorials</a></li>
                        </ul>
                        {/* <h6 className='top_btmgap'><a target='_blank' rel='noopener noreferrer' href='https://mimik.com/'>mimik website</a></h6>
                        <h6><a target='_blank' rel='noopener noreferrer' href='https://mimik.com/lets-edgifi-blog/'>let’s edgifi blog</a></h6>
                        <h6><a target='_blank' rel='noopener noreferrer' href='https://mimik.com/technical-post/'>technical posts</a></h6>
                        <h6><a target='_blank' rel='noopener noreferrer' href='https://mimik.com/event/'>events</a></h6>
                        <h6><a target='_blank' rel='noopener noreferrer' href='https://mimik.com/about-us/'>about us</a></h6> */}
                    </div>
                    <div className='searh_outer'>
                        <NewsletterComponent />
                        <div className='social_links'>
                            <ul>
                                <li><a target='_blank' rel='noopener noreferrer' href='https://www.facebook.com/mimik.edgeEngine/'><FaFacebook /></a></li>
                                <li><a target='_blank' rel='noopener noreferrer' href='https://x.com/mimiktech'><FaXTwitter /></a></li>
                                <li><a target='_blank' rel='noopener noreferrer' href='https://www.youtube.com/channel/UCZLLcmoNZoGnaZaEOx9xF6w/videos'><FaYoutube /></a></li>
                                <li><a target='_blank' rel='noopener noreferrer' href='https://www.linkedin.com/company/mimiktech/'><FaLinkedin /></a></li>
                            </ul>
                        </div>
                        <div className='btm_links_new hide_on_mobile'>
                            <h6><a target='_blank' rel='noopener noreferrer' href={DEVELOPER_MIMIK_URL + 'login'}>developer console</a></h6>
                            <h6><Link to={'/developer-agreement'}>developer agreement</Link></h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container_fluid copyright_bg'>
                <div className='container copyright_section'>
                    <div className='copyright'>© mimik technology, Inc. all rights reserved</div>
                    <div className='btmlinks'><Link to={'/privacy/'}>privacy policy</Link><span>|</span><Link to={'/terms-of-service/'}>terms of service</Link></div>
                </div>
            </div>
        </>
    )
}

export default FooterComponent